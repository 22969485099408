import React, {useState , useContext} from 'react';
import ApplicationContext from '../Contexts/Application.context';

const ApplicationProvider = ({children}) => {

    const [connectedUser, changeConnectedUser] = useState(useContext(ApplicationContext).connectedUser)



    const setConnectedUser = user => changeConnectedUser(user)

    return (
        <ApplicationContext.Provider value={{
            connectedUser,
            setConnectedUser
        }} >
            {children}
        </ApplicationContext.Provider>
    )
}

export default ApplicationProvider