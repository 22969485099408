import React, { useState, useEffect } from 'react';
import Fetcher from '../WebServices/Fetcher';
import {
    SaveIcon,
    UserCircleIcon,
    TrashIcon
} from "@heroicons/react/outline";
import NotifySuccess from './Notifications/Succes.component';
import NotifyAttention from './Notifications/Attention.component';
import { decrypt } from '../Utils/Security/hasher';
import Cookies from 'js-cookie';
import Config, { AppKEY } from '../Config';
import { BaseUrl } from '../WebServices/WebResources';
import moment from 'moment';



const zip_To_Base64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
})

function base64(file, callback){
    var coolFile = {};
    function readerOnload(e){
      var base64 = btoa(e.target.result);
      coolFile.base64 = base64;
      callback(coolFile)
    };
  
    var reader = new FileReader();
    reader.onload = readerOnload;
  
    //var file = file[0].files[0];
    coolFile.filetype = file.type;
    coolFile.size = file.size;
    coolFile.filename = file.name;
    reader.readAsBinaryString(file);
  }

const News = () => {

    const [news, setnews] = useState([])
    const [newNews, setnewNews] = useState(null)
    const [Notification, setNotification] = useState(null)
    const [uploadingFile, setuploadingFile] = useState(false)

    const [pickedPicture, setpickedPicture] = useState(null)


    const getNews = () => {

        const action = async () => {
            await Fetcher.GetNews().then(json => {
                console.log("NEWS ::", json);
                setnews(json.data)
            })
        }
        action()
    }

    useEffect(() => {
        getNews()
    }, [])

    const postNews = () => {
        const mainAction = async () => {
            const action = async p => {
                await Fetcher.PostNews(p).then(json => {
                    if (json && json.data) {
                        setnewNews(null)
                        setpickedPicture(null)

                        document.getElementById("title").value = ""
                        document.getElementById("subtitle").value = ""
                        document.getElementById("contain").value = ""

                        setNotification({ UI: NotifySuccess, state: { title: "Nouvelle actualité postée", message: "Vous venez de poster une actualité portant sur [ " + json.data.title + " ]" } })
                        setTimeout(() => {
                            setNotification(null)
                        }, 5000)

                        getNews()

                    } else {
                        setNotification({ UI: NotifyAttention, state: { title: "Une erreur est survenu!", message: json?.message } })
                        setTimeout(() => {
                            setNotification(null)
                        }, 5000)
                    }
                })
            }

            base64(pickedPicture, function(data){
                action({
                    title: newNews.title,
                    subtitle: newNews.subtitle,
                    contain: newNews.contain,
                    userId: decrypt(Cookies.get(Config.cookiesValues.userId), AppKEY),
                    filename: pickedPicture.name,
                    filesize: pickedPicture.size,
                    filecontent: data.base64
                })
              })
            // await zip_To_Base64(pickedPicture).then(base64FileContent => action({
            //     title: newNews.title,
            //     subtitle: newNews.subtitle,
            //     contain: newNews.contain,
            //     userId: decrypt(Cookies.get(Config.cookiesValues.userId), AppKEY),
            //     filename: pickedPicture.name,
            //     filesize: pickedPicture.size,
            //     filecontent: base64FileContent
            // }))
        }
        if (newNews && Object.keys(newNews).length === 3 && pickedPicture) {
            if (pickedPicture.size <= Config.MAX_COVER_IMAGE_SIZE)
                mainAction()
            else
                alert("Image de couverture trop volumineuse")
        } else {
            setNotification({ UI: NotifyAttention, state: { title: "Champs vide!", message: "Remplissez tous les champs avant de créer le compte" } })
            setTimeout(() => {
                setNotification(null)
            }, 5000)
        }

    }

    const deleteNews = (newsId) => {

        const action = async p => {
            await Fetcher.RemoveNews({newsId : p}).then(json => {

                 setNotification({ UI: NotifySuccess, state: { title: "Actualité supprimée!", message: json.message /*"Vous avez supprimé une actualité publiée, la suppression n'est jamais totale. pour la recupérer, contactez le support technique"*/ } })
                setTimeout(() => {
                    setNotification(null)
                }, 5000)

                getNews()
            })
        }

        action(newsId)
    }




    return (


        <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex">
            {/* Main area */}


            {/* Primary column */}
            <section
                aria-labelledby="primary-heading"
                className="min-w-0 flex-1 h-full flex flex-col "
            >
                <h1 id="primary-heading" className="sr-only">
                    Home
                </h1>
                {/* Your content */}

                <div className="w-full h-full flex flex-col px-8 py-6 space-y-6">
                    <div className="text-2xl text-indigo-500 font-bold">Toutes vos actualités</div>

                    {Notification ? <Notification.UI {...Notification.state} /> : null}

                    <div className="flex-auto w-full  flex overflow-y-auto scrollable px-2">

                        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 place-content-start space-y-2 gap-4">

                            {news.length ?
                                news.map(info => (
                                    <div className="cursor-pointer tooltip">
                                        <div className="sm:flex flex-1 rounded-md shadow p-2 bg-gray-50 focus:bg-indigo-200">
                                            <div className="mb-4 h-auto w-1/3  flex-shrink-0 sm:mb-0 sm:mr-4">
                                                <img
                                                    alt={info.title}
                                                    src={BaseUrl + info.image}
                                                    className="h-full w-full object-cover"
                                                />
                                            </div>
                                            <div >
                                                <h4 className="text-lg font-bold text-gray-700">{info.title}</h4>
                                                <h4 className="text-md font-medium text-indigo-500">{info.subtitle}</h4>
                                                <div className="text-sm text-gray-400 py-2">Publié le <em><strong>{ moment(info.pubDate).format('yyyy-MM-DD HH:mm:ss') }</strong></em></div>
                                                <div className=" text-sm lining-nums">
                                                    {info.contain.substring(0, 100)+ "...."}
                                                </div>
                                                <div className="flex pt-2 w-full place-items-end items-center space-x-3">
                                                    <div className="text-center self-end px-3  ring-1 ring-green-500 text-xs font-normal rounded-sm bg-green-200 text-green-600">
                                                        Editeur : <span className="font-bold text-xs text-green-700">{info.publisher.username}</span> 
                                                    </div>
                                                    <div 
                                                    onClick={()=>deleteNews(info.id)}
                                                    className="self-end flex items-center px-2 space-x-2 font-medium hover:bg-gray-600 hover:text-white  text-gray-500 text-xs tip ring-1 rounded-sm ring-gray-500 bg-gray-50">
                                                        <TrashIcon className="w-5 h-5  " />
                                                        <span>Supprimer</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                )

                                ) : null
                            }


                        </div>



                    </div>
                </div>



            </section>

            {/* Secondary column (hidden on smaller screens) */}
            <aside className="hidden lg:block lg:flex-shrink-0 lg:order-first">
                <div className="h-full relative flex flex-col w-96 border-r border-gray-200 bg-gray-100 overflow-y-auto">
                    {/* Your content */}

                    <div className="w-full h-full flex flex-col py-2 px-5 items-center">

                        <div className="font-bold text-gray-800 text-lg py-4">Faites un post d'actualité</div>

                        <div className="col-span-6 sm:col-span-3 w-full mb-2">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Titre de l'actualité
                            </label>
                            <input
                                type="text"
                                name="first-name"
                                id="title"
                                onChange={event => setnewNews({ ...newNews, title: event.target.value })}
                                required
                                className="outline-none mt-1 text-black focus-within:text-indigo-500 focus-within:font-bold font-medium border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3 w-full mb-2">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Sous titre
                            </label>
                            <textarea
                                type="text"
                                style={{ resize: "none" }}
                                required
                                name="first-name"
                                id="subtitle"
                                onChange={event => setnewNews({ ...newNews, subtitle: event.target.value })}
                                className="outline-none mt-1 text-black focus-within:text-indigo-500 focus-within:font-bold font-medium border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3 w-full mb-2">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Contenu à publier
                            </label>
                            <textarea
                                type="text"
                                style={{ resize: "none" }}
                                placeholder="Ecrire le contenu de l'actualité"
                                required
                                name="first-name"
                                id="contain"
                                onChange={event => setnewNews({ ...newNews, contain: event.target.value })}
                                className="overflow-y-auto scrollable py-2 h-44 text-xs outline-none mt-1 text-black focus-within:text-indigo-500  border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>


                        <div className="col-span-6 sm:col-span-3 w-full mb-2">
                            <label className="block text-sm font-medium text-gray-700">Image de couverture</label>
                            <div
                                onClick={() => document.getElementById('file-upload').click()}
                                className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                <div className="space-y-1 text-center">
                                    <svg
                                        className="mx-auto h-12 w-12 text-gray-400 cursor-pointer"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                    >
                                        <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <div className="flex text-sm text-gray-600">
                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer  rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                        >
                                            <span>Selectionner un fichier</span>
                                            <input hidden id="file-upload" name="file-upload" type="file" className="outline-none sr-only" onChange={e => setpickedPicture(e.target.files[0])} accept=".png,.jpg,.gif,.zip" />
                                        </label>
                                        <p className="pl-1">ou Glissez-Déposez</p>
                                    </div>
                                    {pickedPicture ?
                                        <p className="text-xs font-medium text-red-500">{pickedPicture.name} <b className="font-bold text-red-900">{(pickedPicture.size / 1024 / 1024).toFixed(2)} Mo</b></p> :
                                        <p className="text-xs text-gray-500">PNG, JPG, moins de 1 MB</p>}
                                </div>
                            </div>
                        </div>
                        <button onClick={() => { postNews() }} className="rounded mt-4 focus:ring-2 focus:ring-offset-purple-600 cursor-pointer flex w-full space-x-3 font-medium hover:font-bold text-white py-1 px-3 items-center justify-center bg-indigo-500 hover:bg-indigo-600">
                            <SaveIcon className="w-6 h-6" />
                            <div>Enregistrer</div>
                        </button>

                    </div>






                </div>


            </aside>
        </main >
    )
}

export default News