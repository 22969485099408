let Env = "PROD" ;
const rootDomain =  Env.includes("Dev") ?  "http://codenoradmin.lab/api" : "https://codenor.site/api/public/api"
const domain = rootDomain + "/v1.0"

const WebResources =   {
    signIn : domain + "/users/signIn",
    signUp : domain + "/users/signUp",
    GetUsers : domain + "/users/all",
    GetAllNews : domain + "/news/admin/all",
    PostNews : domain + "/news/admin/post",
    EditNews : domain + "/news/admin/edit",
    RemoveNews : domain + "/news/admin/remove",

}

export default WebResources;

export const BaseUrl = 'https://codenor.site/api/public/';