import logo from './logo.svg';
import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import Urls from './Routes/Urls';
import Cookies from 'js-cookie';
import './App.css';
import Login from './Components/Login.component';
import Config, { AppKEY } from './Config';
import {decrypt} from './Utils/Security/hasher';
import Manager from './Components/App.component';

const App = ({history : {path}}) => {


  return(
    <div>

      <Redirect exact to={Urls.login} path={'/'} />

      <Route
        exact
        path={Urls.login}
        render={() => {
          return Cookies.get(Config.cookiesValues.access_token) ?
            <Redirect exact to={ Urls.users } />
            :
            <Login />
        }}
      />

      <Route exact path={Urls.users} render = { () => <Manager/>}/>
      <Route exact path={Urls.news} render = { () => <Manager/>}/>
      
    </div>
  )
}

export default withRouter(App) ;
