import React, { useState } from 'react';
import {withRouter} from 'react-router-dom';
import Cookies from 'js-cookie';
import { LockClosedIcon, XIcon } from '@heroicons/react/solid'
import Logo from '../Assets/images/logo.png';
import Fetcher from '../WebServices/Fetcher';
import { encrypt } from '../Utils/Security/hasher';
import Config, { AppKEY } from '../Config';
import Urls from '../Routes/Urls';
import animate from 'animate.css';

const Login = ({history : {push}}) => {

const [credentials, setcredentials] = useState(null)

const [alertText, setalertText] = useState(null)

/**
 * Connexion 
 */
const logIn = (event) => {
  event.preventDefault()
  const action = async (params) => {
    return await Fetcher.SignIn(params).then(json => {
      console.log("user :::", json);
      if(json && json.data){
        //save cookies and push
        Cookies.set(Config.cookiesValues.username, encrypt(json.data.username, AppKEY), {expires : 1})
        Cookies.set(Config.cookiesValues.email, encrypt(json.data.email, AppKEY), {expires : 1})
        Cookies.set(Config.cookiesValues.userId, encrypt(json.data.id, AppKEY), {expires : 1})
        Cookies.set(Config.cookiesValues.access_token, encrypt(
           json.data.id + ""
          .concat("--")
          .concat(json.data.username)
          .concat("--")
          .concat(json.data.email) , AppKEY
          ), {expires : 1})
        push(Urls.AdminUrl)
      }else{
        setalertText(json?.message)
    setTimeout(() => {
      setalertText(null)
    }, 3000);
      }
    })
  }
  if(credentials && Object.keys(credentials).length === 2){
    action(credentials)
  }else{
    setalertText("Renseignez email et mot de passe")
    setTimeout(() => {
      setalertText(null)
    }, 3000);
  }
}


return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={Logo}
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-bold text-blue-900">Codenor Manager</h2>
            <h2 className="mt-6 text-center text-xl font-bold text-gray-900">Connexion</h2>
            {/* <p className="mt-2 text-center text-sm text-gray-600">
              Ou{' '}
              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                Contactez l'administrateur pour la creation de votre compte
              </a>
            </p> */}

            { alertText ? 
            <div className="animate__animated animate__fadeIn mt-6 w-auto px-3 py-3 rounded-md text-sm font-semibold text-red-500 ring-1 ring-red-500 space-x-3 flex items-center bg-red-200">
              <XIcon className="w-4 h-4 text-red-500 "/>
              <div>{alertText}</div>
            </div> 
            : null
            }

          </div>
          <form className="mt-8 space-y-6" action="#" method="POST" onSubmit = {event => logIn(event)}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={event => setcredentials({...credentials, email : event.target.value})}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={event => setcredentials({...credentials, password : event.target.value})}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Mot de passe oublié?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                Se connecter
              </button>
            </div>
          </form>
        </div>
      </div>
)

}

export default withRouter(Login)