export const AppKEY = "Q09ERU5PUiBBUFAga2V5"

const Config = {
    MAX_COVER_IMAGE_SIZE : 1048576,
    cookiesValues : {
        access_token : "QSDJKSDQ5FSDQ",
        username : "VSDBDFDFBFDG",
        email : "VQDQJFSDUIRZE",
        userId : "EGRHZFEZRGZR",
    }


}
export default Config