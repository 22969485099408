import axios from 'axios';
import WebResources from './WebResources';
import { decrypt } from '../Utils/Security/hasher';
import Cookies from 'js-cookie';
import AppKEY from '../Config'
import Config from '../Config'

const options = () => {
    return {
        headers: { 
            Authorization: "Bearer ".concat(Cookies.get(Config.cookiesValues.access_token) ? decrypt(Cookies.get(Config.cookiesValues.access_token).trim(), AppKEY) : "") }
    }
}





const Fetcher = {
    /**
     * Async Task to SignIn
     * @param {*} params 
     * @returns 
     */
    SignIn: async (params) => {
        return await axios.post(WebResources.signIn, params).then(res => res.data)
    },
    /**
     * 
     * @param {*} params 
     * @returns 
     */
    SignUp: async (params) => {
        return await axios.post(WebResources.signUp, params).then(res => res.data)
    },
    /**
     * 
     * @param {*} params 
     * @returns 
     */
    GetUsers: async () => {
        return await axios.get(WebResources.GetUsers).then(res => res.data)
    },
    /**
     * 
     * @param {*} params 
     * @returns 
     */
    PostNews: async (params) => {
        return await axios.post(WebResources.PostNews, params).then(res => res.data)
    },
    /**
     * 
     * @param {*} params 
     * @returns 
     */
    GetNews: async () => {
        return await axios.get(WebResources.GetAllNews).then(res => res.data)
    },
    /**
     * 
     * @returns 
     */
    EditNews: async (params) => {
        return await axios.post(WebResources.EditNews, params).then(res => res.data)
    },
    /**
     * 
     * @param {*} params 
     * @returns 
     */
    RemoveNews: async (params) => {
        return await axios.post(WebResources.RemoveNews, params).then(res => res.data)
    },




}

export default Fetcher
