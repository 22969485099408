import React, { useState, useEffect } from 'react';
import Fetcher from '../WebServices/Fetcher';
import {
    SaveIcon,
    UserCircleIcon
} from "@heroicons/react/outline";
import NotifySuccess from './Notifications/Succes.component';
import NotifyAttention from './Notifications/Attention.component';
import { get } from 'js-cookie';
const Users = () => {

    const [users, setusers] = useState([])
    const [newUser, setnewUser] = useState(null)
    const [Notification, setNotification] = useState(null)



    const getUsers = () => {

        const action = async () => {
            await Fetcher.GetUsers().then(json => {
                setusers(json.data)
            })
        }
        action()
    }

    useEffect(()=>{
        getUsers()
    },[])

    const createAccount = () => {
        const action = async p => {
            await Fetcher.SignUp(p).then(json => {
                if (json && json.data) {
                    setnewUser(null)

                    setNotification({ UI: NotifySuccess, state: { title: "Nouveau compte administrateur", message: "Administrateur "+json.data.email + "créé avec succès" } })
                    setTimeout(() => {
                        setNotification(null)
                    }, 5000)

                    getUsers()

                } else {
                    setNotification({ UI: NotifyAttention, state: { title: "Une erreur est survenu!", message: json?.message } })
                    setTimeout(() => {
                        setNotification(null)
                    }, 5000)
                }
            })
        }
        if (newUser && Object.keys(newUser).length === 3) {
            if (document.getElementById("pass1").value === document.getElementById('pass2').value)
                action(newUser)
            else
                alert("Les mots de passe renseignés ne sont pas identiques")
        } else {
            setNotification({ UI: NotifyAttention, state: { title: "Champs vide!", message: "Remplissez tous les champs avant de créer le compte" } })
            setTimeout(() => {
                setNotification(null)
            }, 5000)
        }

    }




    return (


        <main className="min-w-0 flex-1 border-t border-gray-200 lg:flex">
            {/* Main area */}


            {/* Primary column */}
            <section
                aria-labelledby="primary-heading"
                className="min-w-0 flex-1 h-full flex flex-col "
            >
                <h1 id="primary-heading" className="sr-only">
                    Home
                </h1>
                {/* Your content */}

                <div className="w-full h-full flex flex-col px-8 py-6 space-y-6">
                    <div className="text-2xl text-indigo-500 font-bold">Comptes administrateurs</div>

                    {Notification ? <Notification.UI {...Notification.state} /> : null}

                    <div className="flex-auto w-full  flex overflow-y-auto scrollable px-2">

                        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-3 place-content-start space-y-2 gap-4">

                            {
                                users.map(user => (
                                    <div className="cursor-pointer">
                                        <div className="sm:flex flex-1 rounded-md shadow p-2 bg-gray-50">
                                            <div className="mb-4  flex-shrink-0 sm:mb-0 sm:mr-4">
                                                <UserCircleIcon
                                                    className="h-12 w-12 border border-gray-300 bg-white font-normal rounded-full text-gray-300"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div >
                                                <h4 className="text-md font-bold text-gray-800">{user.username}</h4>
                                                <div className=" text-sm ">
                                                    Utilisateur administrateur crée le {user.created_at}, <b>Email :</b> <span className="text-indigo-500 ">{user.email}</span>
                                                </div>
                                                {user.active ? <div className="flex">
                                                    <div className="text-center self-end w-16 text-xs font-medium rounded bg-yellow-200 text-gray-800">
                                                        Actif
                                                    </div>
                                                </div> : null}

                                            </div>
                                        </div>
                                    </div>

                                )

                                )
                            }


                        </div>



                    </div>
                </div>



            </section>

            {/* Secondary column (hidden on smaller screens) */}
            <aside className="hidden lg:block lg:flex-shrink-0 lg:order-first">
                <div className="h-full relative flex flex-col w-96 border-r border-gray-200 bg-gray-100 overflow-y-auto">
                    {/* Your content */}

                    <div className="w-full h-full flex flex-col py-2 px-5 items-center">

                        <div className="font-bold text-gray-800 text-lg py-4">Create a user account</div>

                        <div className="col-span-6 sm:col-span-3 w-full mb-2">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Nom d'utilisateur
                            </label>
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                onChange={event => setnewUser({ ...newUser, username: event.target.value })}
                                required
                                autoComplete="given-name"
                                className="outline-none mt-1 text-black focus-within:text-indigo-500 focus-within:font-bold font-medium border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3 w-full mb-2">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Adresse email
                            </label>
                            <input
                                type="email"
                                required
                                name="first-name"
                                id="first-name"
                                onChange={event => setnewUser({ ...newUser, email: event.target.value })}
                                autoComplete="email"
                                className="outline-none mt-1 text-black focus-within:text-indigo-500 focus-within:font-bold font-medium border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3 w-full mb-2">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Mot de passe
                            </label>
                            <input
                                type="password"
                                name="first-name"
                                id="pass1"
                                className="outline-none mt-1 text-black focus-within:text-indigo-500 focus-within:font-bold font-medium border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-3 w-full mb-4">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                Confirmer mot de passe
                            </label>
                            <input
                                type="password"
                                name="first-name"
                                id="pass2"
                                onChange={event => setnewUser({ ...newUser, password: event.target.value })}
                                className="outline-none text-base mt-1 text-black focus-within:text-indigo-500 focus-within:font-bold font-medium border-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 py-2 shadow-sm sm:text-sm text-base border-gray-300 rounded-md"
                            />
                        </div>

                        <button onClick={() => createAccount()} className="rounded focus:ring-2 focus:ring-offset-purple-600 cursor-pointer flex w-full space-x-3 font-medium hover:font-bold text-white py-1 px-3 items-center justify-center bg-indigo-500 hover:bg-indigo-600">
                            <SaveIcon className="w-6 h-6" />
                            <div>Enregistrer</div>
                        </button>


                    </div>


                </div>
            </aside>
        </main>
    )
}

export default Users