import CryptoJS from 'crypto-js';
import { AppKEY } from '../../Config';
const nonce = AppKEY.length;

export const encrypt = (phrase, key) =>{
    const encrypted = CryptoJS.AES.encrypt(phrase.toString(), key)
    return encrypted
    // const hmac = CryptoJS.HmacSHA256(encrypted, nonce)
    // const object = {e : encrypted, h : hmac}
    // return CryptoJS.enc.Base64.stringify(JSON.stringify(object))  
}

export const decrypt = (phrase, key) =>{
    // const secretObject = JSON.parse(CryptoJS.enc.Base64.parse(phrase))
    // if(Object.keys(secretObject).length === 2 ){
        const decrypted = CryptoJS.AES.decrypt(/*secretObject.e*/ phrase, key)
        //if(secretObject.h === CryptoJS.HmacSHA256(CryptoJS.AES.encrypt(decrypted.toString(CryptoJS.enc.Utf8), key), nonce)){
            return decrypted.toString(CryptoJS.enc.Utf8)
        // }else{
        //     return null
        // }
    //}
}